<template>
  <div>
    <v-icon
      color="primary"
      dark
      @click="onClick"
      class="ap-hover-icon"
    >
      help_outline
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'TheToolbarHelpIcon',
  methods: {
    onClick() {
      window.open('http://aphelp.s3-website-us-west-2.amazonaws.com/ap/help.htm', '_blank');
    },
  },
};
</script>
